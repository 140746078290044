.add-token-box {
  position: fixed;
  left: 10vw;
  right: 10vw;
  top: 20vh;
  bottom: 20vh;
  background: #2c292d;
  border: 3px solid #ff6188;
  box-shadow: #222 10px -10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-token-box .selected-date {
  text-align: center;
  font-size: 1.5rem;
  color: #ff6188;
}

.add-token-box .button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5em
}

.add-token-box .button-container button {
  background: #2c292d;
  text-align: center;

  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-top-color: #ff6188;
  border-left-color: #ff6188;
  border-right-color: #943655;
  border-bottom-color: #943655;
  margin: -1px;

  color: #ff6188;
  padding: 0 1em;
}

.add-token-box .vice-options div {
  padding: .5em 0;
}

.add-token-box .vice-options div label {
  color: #ff6188;
  font-size: 1.3rem;
}

.add-token-box .vice-options div label span {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #2c292d;
  color: #2c292d;
  border-width: 1px;
  border-style: solid;
  border-top-color: #ff6188;
  border-left-color: #ff6188;
  border-right-color: #943655;
  border-bottom-color: #943655;
  border-radius: 3px;
  padding: .25rem .5rem .15rem;
  font-size: 1.3rem;
  margin-right: 1rem
}

.add-token-box .vice-options div label input:checked ~ span  {
  color: #ff6188;
}

.add-token-box .vice-options div label input {
  opacity: 0;
}