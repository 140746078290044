html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  /* background: #333; */
  background: #2c292d;
  font-family: sans-serif;
}

button:disabled {
  opacity: 0.5;
}

.yellow {
  color: #ffd866;
}

.blue {
  color: #78dce8;
}

.red {
  color: #ff6188;
}

.green {
  color: #a9dc76;
}

.purple,
.violet {
  color: #ab9df2;
}

.orange {
  color: #fc9867;
}

.grey,
.gray {
  color: #908e8f
}

.white {
  color: #fdf9f3
}

.black {
  color: #2c292d
}