th {
  font-weight: normal;
  text-decoration: underline;
}

.current-month-and-year {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

td.date-cell div {
  background: #bcbbbb;
  text-align: center;

  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-top-color: #bcbbbb;
  border-left-color: #bcbbbb;
  border-right-color: #908e8f;
  border-bottom-color: #908e8f;
  margin: -1px;

  color: #2c292d;
}

td.date-cell.vice div {
  background: #908e8f;
  text-align: center;
}
td.date-cell.current-month div {
  background: #fdf9f3;
}
td.date-cell.current-month.vice div {
  background: #2c292d;
}


td.date-cell.today div {
  font-weight: bold;
  text-decoration: underline;
  background: #ffd866;
}    


td.date-cell.vice div {
  color: #a9dc76;
}
