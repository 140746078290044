th {
  font-weight: normal;
  text-decoration: underline;
}

.current-month-and-year {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

td.date-cell div {
  background: #bcbbbb;
  text-align: center;

  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-top-color: #bcbbbb;
  border-left-color: #bcbbbb;
  border-right-color: #908e8f;
  border-bottom-color: #908e8f;
  margin: -1px;

  color: #2c292d;
}

td.date-cell.vice div {
  background: #908e8f;
  text-align: center;
}
td.date-cell.current-month div {
  background: #fdf9f3;
}
td.date-cell.current-month.vice div {
  background: #2c292d;
}


td.date-cell.today div {
  font-weight: bold;
  text-decoration: underline;
  background: #ffd866;
}    


td.date-cell.vice div {
  color: #a9dc76;
}

.calendar-container {
  background: #fdf9f3;
  padding: 1em;
  color: #2c292d;
  border-top: 4px solid #ab9df2;
  border-bottom: 4px solid #ab9df2;
  margin-top: 6rem;
}

.add-token-box {
  position: fixed;
  left: 10vw;
  right: 10vw;
  top: 20vh;
  bottom: 20vh;
  background: #2c292d;
  border: 3px solid #ff6188;
  box-shadow: #222 10px -10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.add-token-box .selected-date {
  text-align: center;
  font-size: 1.5rem;
  color: #ff6188;
}

.add-token-box .button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5em
}

.add-token-box .button-container button {
  background: #2c292d;
  text-align: center;

  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-top-color: #ff6188;
  border-left-color: #ff6188;
  border-right-color: #943655;
  border-bottom-color: #943655;
  margin: -1px;

  color: #ff6188;
  padding: 0 1em;
}

.add-token-box .vice-options div {
  padding: .5em 0;
}

.add-token-box .vice-options div label {
  color: #ff6188;
  font-size: 1.3rem;
}

.add-token-box .vice-options div label span {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #2c292d;
  color: #2c292d;
  border-width: 1px;
  border-style: solid;
  border-top-color: #ff6188;
  border-left-color: #ff6188;
  border-right-color: #943655;
  border-bottom-color: #943655;
  border-radius: 3px;
  padding: .25rem .5rem .15rem;
  font-size: 1.3rem;
  margin-right: 1rem
}

.add-token-box .vice-options div label input:checked ~ span  {
  color: #ff6188;
}

.add-token-box .vice-options div label input {
  opacity: 0;
}
.date-selector-container {
  text-align: center;
  margin-top: 1em;
}

.date-selector-container select {
  font-size: 2rem;
}

.quote-or-something {
  border-top: 4px solid #74dfff;
  border-bottom: 4px solid #74dfff;
  margin-top: 1em;
  text-align: center;
  padding: 1em;
  color: #fdf9f3;
  font-family: monospace;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  /* background: #333; */
  background: #2c292d;
  font-family: sans-serif;
}

button:disabled {
  opacity: 0.5;
}

.yellow {
  color: #ffd866;
}

.blue {
  color: #78dce8;
}

.red {
  color: #ff6188;
}

.green {
  color: #a9dc76;
}

.purple,
.violet {
  color: #ab9df2;
}

.orange {
  color: #fc9867;
}

.grey,
.gray {
  color: #908e8f
}

.white {
  color: #fdf9f3
}

.black {
  color: #2c292d
}
